import React, { useState, useContext, useEffect, } from "react";
import { diamondDepth , diamondDepthForManual} from "../../utils/diamondData";
import { PriceContext } from "../../context";
import Modal from "react-bootstrap/Modal";
const DepthForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData , reportType } = getContext;
  const { trueFaceUp } = pricestepData || {};
  
  const [trueFace, settrueFace] = useState(trueFaceUp?trueFaceUp:"Select");

  useEffect(() => {
    settrueFace(pricestepData?.trueFaceUp? trueFaceUp:"Select")
    // eslint-disable-next-line
  }, [pricestepData?.shape]);

  const [show, setShow] = useState(false);
  const handleSelect = (value, name, depthName) => {
    settrueFace(value)
    setPricestepData({ ...pricestepData, [name]: value , defaultIdu: "Default IDU" });
    setShow(false);
  };

  const handleModal = () => {
    if (reportType === "byGia" ) {
      return;
    }
    setShow(!show);
  };

  return (
    <>
      <div className="form-wrapper">

      <div
           className={`${reportType ==="byGia"? "disable_input":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${pricestepData[name] === "" ? "0.5" : "1"}`,
          }}
        >
          <h6>{trueFace}</h6>
        </div>
        
      </div>
      <DepthSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
        pricestepData={pricestepData}
        reportType={reportType}
      />
    </>
  );
};

export default DepthForm;

const DepthSelector = ({
  show,
  handleModal,
  handleSelect,
  name,
  pricestepData,
  reportType
}) => {
  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
        

{(reportType ==='byGia' ?  diamondDepth: diamondDepthForManual).map((el, i) => (
                <div
                  className={`pi-option-elem  ${
                    el.displayName === "Face-Up - Large" && pricestepData?.shape === "Round"
                ? "step-option-disable"
                : ""
            }`}
                  onClick={() =>
                  {
                    if(el.displayName === "Face-Up - Large" && pricestepData?.shape === "Round")
                    {
                      return;
                    }
                    handleSelect(el.displayName, name)}
                  }
                    
                   

                  key={i}
                >
                     {el.displayName}
                </div>
              ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
