import React, { useState } from "react";
import PriceIntelligenceLayout from "..";
import Header from "../header";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import VisibleIcon from "../../assets/icon/visibleIcon";
import NotVisibleIcon from "../../assets/icon/notVisibleIcon";
import arrow from "../../assets/images/pricing/arrow.svg";
import { priceIntelligenceInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const UserRegister = () => {
  const navigate=useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const initialState = {
      email:"",
      lastName:"",
      firstName:"",
      phone:"",
      password:"",
      role:"priceIntelligence"
    };
 const [payload, setPayload] = useState(initialState);

 const handleOnChange = (e) => {
  const { name, value } = e.target;
  setPayload({ ...payload, [name]: value });
  
};

const handleSubmit = async (e) => {
  try {
    const response = await priceIntelligenceInstance().post(
      "/users/v2/register",
      payload
    );
    const { status, msg, } = response.data || {};
    if (status === 200) {
      toast.success(msg, { toastId: "loginsuccess" });
      navigate("/welcome");
      return;
    }
    else if(msg==="User already registerd"){
setTimeout(() => {
  navigate("/user-login")
}, 4000);
    }
    toast.error(msg, { toastId: "loginerror" });
    
  } catch (e) {
    e.response.data.message.map((item,index)=>{
      return toast.error(item, { toastId: "loginerror" });
    })
  }
};
  return (
    <PriceIntelligenceLayout>
      <div className="pricing-userlogin">
        <Header />
        <div className="pricing-userlogin-content">
          <div className="pricing-userlogin-content-form">
            <h1>User Registration</h1>
            <div className="pricing-userlogin-content-input">
              <FloatingLabel
                
                label="Email address *"  
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  name="email"
                  onChange={(e) => handleOnChange(e)}   
                />
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid email
                </Form.Text>
              <div>
              <FloatingLabel
               
                label="Phone Number *"
                
              >
                <Form.Control
                  type="text"
                  placeholder="phone number"
                  name="phone"
                  onChange={(e) => handleOnChange(e)}  
                />
               
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid Phone Number
                </Form.Text>
              </div>
              <div>
                <FloatingLabel
               
                label="First Name *"
              >
                <Form.Control
                  type="text"
                  placeholder="first name"
                  name="firstName"
                  
                onChange={(e) => handleOnChange(e)}  
                />
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid First Name
                </Form.Text>
              </div>
              <div>
              <FloatingLabel
                
                label="Last Name *"          
              >
                <Form.Control
                  type="text"
                  placeholder="last name"
                  name="lastName"
                  onChange={(e) => handleOnChange(e)} 
                /> 
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid Phone Number
                </Form.Text>
              </div>
              <div>
              <FloatingLabel
                controlId="floatingPassword"
                label="Password *"
                className="error_msg1"
                name="password"
                onChange={(e) => handleOnChange(e)}
                autoComplete="false"
              >
                <Form.Control
                  type={showPassword?"text":"password"}
                  placeholder="Password"
                  name="password"        
                  autoComplete="false"
                  
                />
                <span
                  className="visible-wrapper"
                  role="presentation"
                  onClick={()=>{setShowPassword(!showPassword)}}
                >
                   {showPassword?<NotVisibleIcon/>:<VisibleIcon />}
                </span>
                
              </FloatingLabel>
              <Form.Text className="d-none">
                  the password should contain 8 letters
                </Form.Text>
            </div>
            </div>
          </div>
          <div className="pricing-userlogin-content-btn">
          <Button onClick={() => handleSubmit()}>
              <span>Next</span>
              <span>
                <img src={arrow} alt="arrow" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </PriceIntelligenceLayout>
  );
};

export default UserRegister;
