import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { diamondSymmetry } from "../../utils/diamondData";
import { PriceContext } from "../../context";

const SymmetryForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData,reportType } = getContext;
  const { symmetry } = pricestepData || {};

  const [show, setShow] = useState(false);
  const [defaultSymmetry, setDefaultSymmetry] = useState(
    symmetry ? symmetry : "Select"
  );
  const handleModal = () => {
    if (reportType === "byGia") {
      return;
    }
    setShow(!show);
  };

  useEffect(() => {
    setDefaultSymmetry(symmetry ? diamondSymmetry?.find((s) => s.code === symmetry)?.displayName : "Select");
  }, [symmetry]);
  const handleSelect = (value, name, showName) => {
    setDefaultSymmetry(showName);
    setPricestepData({ ...pricestepData, [name]: value });
    setShow(false);
  };
  return (
    <>
      <div className="form-wrapper">
        <div
          className={`${reportType ==="byGia"? "disable_input":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${pricestepData[name] === "" ? "0.5" : "1"}`,
          }}
        >
          <h6>{defaultSymmetry}</h6>
        </div>
      </div>
      <SymmetrySelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
      />
    </>
  );
};

export default SymmetryForm;

const SymmetrySelector = ({ show, handleModal, handleSelect, name }) => {
  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
          {diamondSymmetry.map((el, i) => (
            <div
              className="pi-option-elem"
              onClick={() => handleSelect(el.code, name, el.displayName)}
              key={i}
            >
              {el.displayName}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
