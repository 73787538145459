import React from "react";
import { Navigate } from "react-router-dom";

const UnAuthRoute = ({ component: Component }) => {
  if (!localStorage.getItem("fcrf@token")) {
    return Component;
  } else {
    return <Navigate to="/start-pricing" />;
  }
};

export default UnAuthRoute;
