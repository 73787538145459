import React from "react";
import { Button } from "react-bootstrap";

const Home = ({ handleChecked, checkAssets, handleGia, routeChangeManual }) => {

  

  return (
    <div className="start-pricing-form w-100 gap-4 start-pricing-buttons build_price_toggle bg-transparent">
      <div className="tab-btns-wrapper">
        <input
          className="single-pair-check"
          type="checkbox"
          id="checkbox_toggle"
          onChange={handleChecked}
          checked={!checkAssets}
        />
        <div className="single-pair-checkbox build-pair-checkbox">
          <label htmlFor="checkbox_toggle" className="single-pair-slide">
            <label
              htmlFor="checkbox_toggle"
              className="single-pair-toggle"
            ></label>
            <label
              htmlFor="checkbox_toggle"
              className={`single-pair-text ${
                checkAssets  ? "single" : ""
              }`}
            >
              Basic
            </label>
            <label
              htmlFor="checkbox_toggle"
              className={`single-pair-text 
              ${!checkAssets  ? "pair" : ""}`}
            >
              Advanced
            </label>
          </label>
        </div>
      </div>

      <div className="pricing-userlogin-content-btn w-100 gia-text">
        <Button onClick={handleGia}>I have a GIA</Button>
      </div>
      <div className="pricing-userlogin-content-btn w-100  ">
        <Button onClick={routeChangeManual}>Manually</Button>
      </div>

  
    </div>
  );
};

export default Home;
