import { Button, Modal } from "react-bootstrap";
import "./infoModal.css";

const InfoModal = ({ infoModal, setInfoModal, heading, infoTxt,bg_remove_modal }) => {
  return (
    <Modal
      show={infoModal}
      onHide={() => setInfoModal(!infoModal)}
      centered
      className={`${bg_remove_modal} info-modal back-modal `}
    >
      <div className="close-btn">
      </div>
      <Modal.Body>
        <div className="back-modal-body">
          <h4>{heading}</h4>
          <div className="info-content">
            {infoTxt.map((text, i) => (
              <>
                <p key={i}>{text}</p>
              </>
            ))}
          </div>
          <div className="action-grid">
            <Button onClick={() => setInfoModal(false)}>close</Button>
          </div>
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
