
const AuthRoute = ({ component: Component, }) => {
  
        if ( localStorage.getItem('fcrf@token')) {
            return Component;
          } else {
            window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
          }
    
}

export default AuthRoute;