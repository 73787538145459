import { Button, Modal, Spinner } from "react-bootstrap";
import arrow from "../../assets/images/pricing/arrow-white.svg";
import { useState } from "react";
import { priceIntelligenceInstance } from "../../config/axios";

const DisclaimerModal = ({ disclaimerModal, setDisclaimerModal }) => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const checkHandle = () => {
    setBtnDisabled(!btnDisabled);
  };

  const routeChange = async () => {
    try {
      setLoader(true);
      const response = await priceIntelligenceInstance().put(
        "/users/v2/piupdate-disclaimer"
      );
      const { status } = response.data || {};
      if (status === 200) {
        setLoader(false);
        setDisclaimerModal(false);
        return;
      }
    } catch (e) {}
  };
  return (
    <div>
      <Modal show={disclaimerModal} centered className={` disclaimer-modal `}>
        <div className="pricing-disclaimer-content desktop-view-disclaimer-pricing">
          <div className="pricing-disclaimer-text">
            <h1>Confidentiality Statement</h1>
            <p>
              As the FCRF is committed to protecting the interests of the
              wholesale industry, users are responsible for ensuring that their
              entire organization maintains strict confidentiality of the data.
              By using this unique platform, you are among a very small group of
              professionals with access to this pricing data. You agree to
              maintain the confidentiality of the information and respect the
              delicate balance within the supply chain.
            </p>
          </div>
          <div className="pricing-userlogin-content-btn pricing-disclaimer-btn">
            <div className="custom-box">
              <input
                id="checkbox-1"
                className="checkbox-custom"
                name="checkbox-1"
                type="checkbox"
                onClick={checkHandle}
              />
              <label htmlFor="checkbox-1" className="checkbox-custom-label">
                I agree to the terms of use
              </label>
            </div>
            <Button disabled={btnDisabled} onClick={() => routeChange()}>
              {loader ? <Spinner animation="border" /> : <><span>Next</span>
              <span>
                <img src={arrow} alt="arrow" />
              </span></>}
              
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DisclaimerModal;
