import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Sidebar from "./sidebar";
import barIcon from "../assets/images/pricing/menu-icon-light.svg";

const Header = () => {

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  return (
    <div className="pricing-entrence-header">
      <div className="pricing-entrence-heading">
        <h1>Price Intelligence</h1>
      </div>

      <Button className="menu-toggle  menu-toggle-desk" onClick={handleShow}>
              <img src={barIcon} alt="menu" />
            </Button>
            <Sidebar show={show} handleClose={handleClose} />
    </div>
  );
};

export default Header;
