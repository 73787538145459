import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { diamondColorInnerGrade } from "../../utils/diamondData";
import { PriceContext } from "../../context";

const InnerGradeForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData,reportType } = getContext;
  const [show, setShow] = useState(false);
  const [defaultInnerGrade, setDefaultInnerGrade] = useState("Select");

  useEffect(() => {
    setDefaultInnerGrade(pricestepData?.colorInnerGrade ? pricestepData?.colorInnerGrade : "Select");
    // eslint-disable-next-line
  }, [pricestepData?.saturation]);
  const handleModal = () => {
    setShow(!show);
  };
  const handleSelect = (value, name) => {
    setDefaultInnerGrade(value);
    setPricestepData({ ...pricestepData, [name]: value });
    setShow(false);
  };
  return (
    <>
      <div className="form-wrapper">
        <div
          className={`${reportType ==="byGia"? "disable_input1":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${!!pricestepData[name] ? "1" : "0.5"}`,
          }}
        >
          <h6>{defaultInnerGrade}</h6>
        </div>
      </div>
      <InnerGradeSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
      />
    </>
  );
};

export default InnerGradeForm;

const InnerGradeSelector = ({ show, handleModal, handleSelect, name }) => {
  const getContext = useContext(PriceContext);
  const { pricestepData } = getContext;
  const handleGradeSelect = (el) => {
    if (pricestepData?.saturation !== "Fancy Vivid" && el === "4+") {
      return;
    } else {
      handleSelect(el, name);
    }
  };
  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
          
          {diamondColorInnerGrade.map((el, i) => (
            <div
            className={`pi-option-elem ${
              el === "4+" && pricestepData?.saturation !== "Fancy Vivid"
                ? "step-option-disable"
                : ""
            }`}
            
              onClick={() => handleGradeSelect(el, name)}
              key={i}
            >
              Inner Grade {el}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
