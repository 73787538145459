import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import closeIcon from "../../assets/images/pricing/cross-icon.svg";
import { Button,FloatingLabel, Form } from 'react-bootstrap';
import VisibleIcon from "../../assets/icon/visibleIcon";
import NotVisibleIcon from "../../assets/icon/notVisibleIcon";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { priceIntelligenceInstance } from "../../config/axios";
const UpdatePassword = ({ UpdatePasswordModal, handleUpdatePasswordModal }) => {
  const navigate=useNavigate()
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const initialState = {
    password: "",
    newPassword: "",
  };
  const [payload, setPayload] = useState(initialState);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const handleSubmit = async (e) => {
    try {
      const response = await priceIntelligenceInstance().put(
        "/users/v2/UpdatePiUserpassword",
        payload
      );
      const { status, message } = response.data || {};
      if (status === 200) {
        toast.success(message, { toastId: "loginsuccess" });
        navigate("/user-login")
        return;
      }
      
      toast.error(message, { toastId: "loginerror" });
      
      
    } catch (e) {
     
    if(e.response.data.msg==="Unauthorized Access"){
      toast.error(e.response.data.msg, { toastId: "loginerror" })
    }
else{
 e.response.data.message.map((item,index)=>{
      return toast.error(item, { toastId: "loginerror" });
    })
}
    }
  };
  return (
    <Modal show={UpdatePasswordModal} onHide={handleUpdatePasswordModal} centered>
      <Modal.Header>
        <div className='d-flex w-100 justify-content-between'>
          <h5>Update Password</h5>
          <div onClick={handleUpdatePasswordModal}>
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="pricing-userlogin-content-input">
          <div>
            <FloatingLabel
              controlId="floatingOldPassword"
              label="Old Password *"
              className="error_msg1"
              name="password"
              onChange={(e) => handleOnChange(e)}
              autoComplete="false"
            >
              <Form.Control
                type={showOldPassword ? "text" : "password"}
                placeholder="Old Password"
                name="password"
                autoComplete="false"
              />
              <span
                className="visible-wrapper"
                role="presentation"
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                {showOldPassword ? <NotVisibleIcon /> : <VisibleIcon />}
              </span>
            </FloatingLabel>
            <Form.Text className="d-none">
              The password should contain 8 letters
            </Form.Text>
          </div>

          <div>
            <FloatingLabel
              controlId="floatingNewPassword"
              label="New Password *"
              className="error_msg1"
              name="newPassword"
              onChange={(e) => handleOnChange(e)}
              autoComplete="false"
            >
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                placeholder="New Password"
                name="newPassword"
                autoComplete="false"
              />
              <span
                className="visible-wrapper"
                role="presentation"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <NotVisibleIcon /> : <VisibleIcon />}
              </span>
            </FloatingLabel>
            <Form.Text className="d-none">
              The password should contain 8 letters
            </Form.Text>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-center'>
          <Button  onClick={() => handleSubmit()} className="updateButton mt-5">
            <span className='mt-1'>Update</span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePassword;

