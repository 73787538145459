import React, { useEffect, useState } from "react";
import PriceIntelligenceLayout from "..";
//import { useLocation } from "react-router-dom";
import Header from "../header";
import Button from "react-bootstrap/Button";
import arrow from "../../assets/images/pricing/arrow-white.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { priceIntelligenceInstance } from "../../config/axios";
const Disclaimer = () => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  let navigate = useNavigate();
  const checkHandle = () => {
    setBtnDisabled(!btnDisabled);
    
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/disclaimer') {
      document.body.classList.add('disclaimer-active');
    } else {
      document.body.classList.remove('disclaimer-active');
    }

    // Clean up on unmount
    return () => {
      document.body.classList.remove('disclaimer-active');
    };
  }, [location]);

  const routeChange = async() => {
    try {
      const response = await priceIntelligenceInstance().put(
        "/users/v2/piupdate-disclaimer");
      const { status} = response.data || {};
      if (status === 200) {
        navigate("/start-pricing");
        return;
      }
    } catch (e) {
      
    }
   
  };
  return (
    <PriceIntelligenceLayout>
      <div className="pricing-disclaimer display-mob-disclaimer">
        <Header />
        <div className="pricing-disclaimer-content">
          <div className="pricing-disclaimer-text">
            <h1>Confidentiality Statement</h1>
            <p>
            As the FCRF is committed to protecting the interests of the wholesale industry, users are responsible for ensuring that their entire organization maintains strict confidentiality of the data. By using this unique platform, you are among a very small group of professionals with access to this pricing data.
            You agree to maintain the confidentiality of the information and respect the delicate balance within the supply chain.
            
            
            </p>
          </div>
          <div className="pricing-userlogin-content-btn pricing-disclaimer-btn">
            <div className="custom-box">
              <input
                id="checkbox-2"
                className="checkbox-custom"
                name="checkbox-1"
                type="checkbox"
                // checked={btnDisabled}
                onChange={checkHandle}
              />
              <label htmlFor="checkbox-1" className="checkbox-custom-label">
                I agree to the terms of use
              </label>
            </div>
            <Button disabled={btnDisabled} onClick={() => routeChange()}>
              <span>Next</span>
            
            </Button>
          </div>
        </div>
      </div>
          <div className="start-pricing display-disclaimer">
        <Header />
        <div className="start-pricing-content position-relative">
          <div className="pricing-entrence-content-img1 pricing-entrence-banner-img start-pricing-text">
          <div className="pricing-disclaimer-content desktop-view-disclaimer-pricing">
          <div className="pricing-disclaimer-text">
            <h1  >Confidentiality Statement</h1>
            <p>
            As the FCRF is committed to protecting the interests of the wholesale industry, users are responsible for ensuring that their entire organization maintains strict confidentiality of the data. By using this unique platform, you are among a very small group of professionals with access to this pricing data.
            You agree to maintain the confidentiality of the information and respect the delicate balance within the supply chain.
            
            </p>
          </div>
          <div className="pricing-userlogin-content-btn pricing-disclaimer-btn">
            <div className="custom-box">
              <input
                id="checkbox-1"
                className="checkbox-custom"
                name="checkbox-1"
                type="checkbox"
                onClick={checkHandle}
              />
              <label htmlFor="checkbox-1" className="checkbox-custom-label">
                I agree to the terms of use
              </label>
            </div>
            <Button disabled={btnDisabled} onClick={() => routeChange()}>
              <span>next</span>
              <span>
                <img src={arrow} alt="arrow" />
              </span>
            </Button>
          </div>
        </div>
          </div>

       
         
        </div>
      </div>
    </PriceIntelligenceLayout>
  );
};

export default Disclaimer;
