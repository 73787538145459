import React, { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { priceIntelligenceInstance } from "../config/axios";
import { calculateThreePercentLess } from "../utils/function";
import { PriceContext } from "../context";
import { useNavigate } from "react-router-dom";
import UAParser from "ua-parser-js";

const Sidebar = ({ show, handleClose }) => {
  const getContext = useContext(PriceContext);
  const navigate = useNavigate();
  const {
    PreviousSearchesData,
    setPreviousSearchesData,
    setPricestepData,
    piCredits,
    setCheckAssets,
  } = getContext;
 
  const [, setUserMails] = useState("");

  const GetPreviousSearchesData = async () => {
    try {
      const response = await priceIntelligenceInstance().get(
        `/price/getAllreport`
      );
      if (response.data.status === 200) {
        setPreviousSearchesData(response.data.resultdata);
      } else {
        setPreviousSearchesData([]);
      }
    } catch (e) {

    }
  };


  function calculateAdjustedPrice(element, checkAssets) {
    var priceValue = element.priceFields.price;

    if (element.giasaturation === "Fancy Deep") {
      priceValue = priceValue * 0.94;
    }

    return calculateThreePercentLess(priceValue, checkAssets) + " per ct";
  }

  // Example usage:

  const handleNavigationRoute = async (element) => {
    const [ipResponse, uaResult] = await Promise.all([
      fetch("https://api.ipify.org?format=json"),
      new Promise((resolve) => resolve(new UAParser().getResult())),
    ]);

    const { ip } = await ipResponse.json();


    const payload = {
      IP: ip,
      "Operating System": uaResult.os.name,
      Browser: uaResult.browser.name,
      Device: uaResult.device.model || "Unknown",
    };

  

    var priceValue = element.priceFields.price;

    if (element.giasaturation === "Fancy Deep") {
      priceValue = priceValue * 0.94;
    }

    const price = calculateThreePercentLess(
      priceValue,
      element?.checkAssets || false
    );

    const jobNumber = element?.jobNumber;
    setPricestepData({ ...element?.priceFields, price, jobNumber });
    setCheckAssets(element?.checkAssets || false);
    const exportData = {
      ...element?.priceFields,
      price,
      jobNumber,
    };

    if (price && jobNumber && element?.priceFields) {
      handleClose();
      navigate("/result", {
        state: {
          checkAsset: element?.checkAssets || false,
          id: element?._id,
          pricetepData: exportData,
          reportType: element?.giaReportNumber ? "byGia" : "manual",
        },
      });
      await priceIntelligenceInstance().post(
        `/price/sendHistoryWebhook/${element?.jobNumber}`,
        payload
      );
    } else {
      return;
    }
  };


  useEffect(() => {
    const usermail = localStorage.getItem("price#@useremail");
    if (usermail) {
      setUserMails(usermail);
    }
  }, []);

  return (
    <Offcanvas
      className="pi-sidebar"
      show={show}
      onHide={handleClose}
      placement="end"
    >
      <Offcanvas.Header closeButton />
      <Offcanvas.Body>
        <Accordion className="sidebar-nav-list">
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => GetPreviousSearchesData()}>
              Previous searches
            </Accordion.Header>
            <Accordion.Body>
              {PreviousSearchesData &&
                PreviousSearchesData.length > 0 &&
                PreviousSearchesData.map((element) => {
                  return (
                    <span
                      onClick={() => handleNavigationRoute(element)}
                      role="presentation"
                      key={element?._id}
                    >
                      #PI{element?.jobNumber}{" "}
                      {element?.giaReportNumber &&
                        `(${element?.giaReportNumber})`}
                      <br />
                      {element?.priceFields?.carat_weight}ct,{" "}
                      {element?.priceFields?.saturation},{" "} {element?.priceFields?.shape}
                      
                      <br />
                      {calculateAdjustedPrice(
                        element,
                        element?.checkAssets || false
                      )}
                    </span>
                  );
                })}
             
            </Accordion.Body>
          </Accordion.Item>
   
          <Accordion.Item eventKey="2">
            <Accordion.Header id="accountSettingHeader">
              Credits : {(piCredits ==="-1"  || piCredits === -1) ? "Unlimited" : piCredits}{" "}
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </Offcanvas.Body>

      <Offcanvas.Header>
        <div className="w-100 d-flex justify-content-between align-items-center">
     
        </div>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default Sidebar;
