import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  diamondFluorescenceColor,
  diamondFluorescenceColorSub,
} from "../../utils/diamondData";
import arrow_down from "../../assets/icon/down_arrow.svg";
import { PriceContext } from "../../context";

const FluorescenceForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData,reportType } = getContext;
  const { fluorescence } = pricestepData || {};

  const [show, setShow] = useState(false);
  const [defaultFluorescence, setDefaultFluorescence] = useState(
    fluorescence ? fluorescence : "Select"
  );
  const handleModal = () => {
    if (reportType === "byGia") {
      return;
    }
    setShow(!show);
  };
  const handleSelect = (value, name) => {
    setDefaultFluorescence(value);
    setPricestepData({ ...pricestepData, [name]: value  });
    setShow(false);
  };
  useEffect(() => {
    setDefaultFluorescence(fluorescence ? fluorescence : "Select");
  } , [fluorescence]);
  return (
    <>
      <div className="form-wrapper">
        <div
          className={`${reportType ==="byGia"? "disable_input":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${pricestepData[name] === "" ? "0.5" : "1"}`,
          }}
        >
          <h6>{defaultFluorescence}</h6>
        </div>
      </div>
      <FluorescenceSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
      />
    </>
  );
};

export default FluorescenceForm;

const FluorescenceSelector = ({ show, handleModal, handleSelect, name }) => {
  const [selectColor, setSelectColor] = useState('None');
  const [accordianState, setAccordianState] = useState({});

  const handleColor = (el) => {
    if (el !== 'None') {
      setSelectColor(el);
    } else {
      handleSelect(`None`, name);
    }
  };

  const handleSubColor = (el) => {
    handleSelect(`${el} ${selectColor}`, name);
  };

  const toggleAccordion = (el) => {
    setAccordianState((prevState) => ({
      ...prevState,
      [el]: !prevState[el],
    }));
  };

  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
          {diamondFluorescenceColor.map((el, i) => (
            <FluorescenceSubSelector
              key={i}
              handleColor={handleColor}
              handleSubColor={handleSubColor}
              selectColor={selectColor}
              el={el}
              accordianState={accordianState}
              toggleAccordion={toggleAccordion}
            />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const FluorescenceSubSelector = ({
  handleColor,
  handleSubColor,
  selectColor,
  el,
  accordianState,
  toggleAccordion,
}) => {
  const isAccordionOpen = accordianState[el] || false;

  const handleAccordian = () => {
    handleColor(el);
    toggleAccordion(el);
  };

  return (
    <div className="accordian_outer">
      <div
        className={`pi-option-elem ${isAccordionOpen && selectColor === el ? 'active_pioption' : ''}`}
        onClick={handleAccordian}
      >
        <div>{el}</div>
        {el !== 'None' && <img src={arrow_down}  alt="acc" height={15} width={15} />}
      </div>
        <div className={`${isAccordionOpen && selectColor === el && el !== 'None'?"open_wrapper":""} sub-option-wrapper`}>
          {diamondFluorescenceColorSub.map((subEl, j) => (
            <div
              className="pi-sub-option-elem"
              onClick={() => handleSubColor(subEl)}
              key={j}
            >
              {subEl} {el}
            </div>
          ))}
        </div>
    </div>
  );
};
