import { Button, Modal } from "react-bootstrap";
import "./errorModal.css";

const ErrorModal = ({ errorModal, setErrorModal, errMsg, infoTxt,bg_remove_modal }) => {
  return (
    <Modal
      show={errorModal}
      onHide={() => setErrorModal(!errorModal)}
      centered
      className={`${bg_remove_modal} info-modal back-modal error-modal`}
    >
      <div className="close-btn">
      </div>
      <Modal.Body>
        <div className="back-modal-body err-modal">
          {errMsg !=="Become a Member" ?<h4>{errMsg}</h4>:
          <p>You've used up the searches included in your account.
          Please contact at  <a href='mailto:customers@fcresearch.org'>
                  customers@fcresearch.org
                </a></p>
          } 
       
         
          <div className="action-grid mt-3">
            <Button onClick={() => setErrorModal(false)}>close</Button>
          </div>
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
