import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { diamondColorUndertone } from "../../utils/diamondData";
import { PriceContext } from "../../context";

const UndertoneForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData,reportType } = getContext;

  const [show, setShow] = useState(false);
  const [defaultUndertone, setDefaultUndertone] = useState("Select");

  useEffect(() => {
    setDefaultUndertone(pricestepData[name] ? pricestepData[name] : "Select");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricestepData?.color]);
  const handleModal = () => {
    setShow(!show);
  };
  const handleSelect = (value, name) => {
    setDefaultUndertone(value);
    setPricestepData({ ...pricestepData, [name]: value , singlePair : "single" });
    setShow(false);
  };
  return (
    <>
      <div className="form-wrapper">
        <div
          className={`${reportType ==="byGia"? "disable_input1":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${!!pricestepData[name] ? "1" : "0.5"}`,
          }}
        >
          <h6>{defaultUndertone}</h6>
        </div>
      </div>
      <UndertoneSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
        pricestepData={pricestepData}
      />
    </>
  );
};

export default UndertoneForm;

const UndertoneSelector = ({
  show,
  handleModal,
  handleSelect,
  name,
  pricestepData,
}) => {

  
  const handleUndertoneSelect = (el) => {
    if (pricestepData?.color !== "Pink" && el === "4+") {
      return;
    } else {
      handleSelect(el, name);
    }
  };
  return (


    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <>
          <div className="input-option-wrapper">
            {diamondColorUndertone.map((el, i) => (
              <div
              className={`pi-option-elem ${
                pricestepData?.color !== "Pink" &&
                el.value === "4+" &&
                "step-option-disable"
              }`}
                onClick={() => handleUndertoneSelect(el.value, name)}
                key={i}
              >
                Undertone {el.value}
              </div>
            ))}
          </div>
         
        </>
      </Modal.Body>
    </Modal>
  );
};
