import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Radiant_img1 from "../../assets/images/Shapes_Image/radiant1.svg";
import Radiant_img2 from "../../assets/images/Shapes_Image/radiant2.svg";
import Radiant_img3 from "../../assets/images/Shapes_Image/radiant3.svg";
import Radiant_img4 from "../../assets/images/Shapes_Image/radiant4.svg";
import Pear_img1 from "../../assets/images/Shapes_Image/pear4.svg";
import Pear_img2 from "../../assets/images/Shapes_Image/pear3.svg";
import Pear_img3 from "../../assets/images/Shapes_Image/pear2.svg";
import Pear_img4 from "../../assets/images/Shapes_Image/pear1.svg";
import Oval_img1 from "../../assets/images/Shapes_Image/Oval1.svg";
import Oval_img2 from "../../assets/images/Shapes_Image/Oval2.svg";
import Oval_img3 from "../../assets/images/Shapes_Image/Oval3.svg";
import Oval_img4 from "../../assets/images/Shapes_Image/Oval4.svg";
import Cusion_img1 from "../../assets/images/Shapes_Image/Cusion1.svg";
import Cusion_img2 from "../../assets/images/Shapes_Image/Cusion2.svg";
import Cusion_img3 from "../../assets/images/Shapes_Image/Cusion3.svg";
import Cusion_img4 from "../../assets/images/Shapes_Image/Cusion4.svg";
import Round_img1 from "../../assets/images/Shapes_Image/round.svg";
import Heart_img1 from "../../assets/images/Shapes_Image/heart1.svg";
import Heart_img2 from "../../assets/images/Shapes_Image/heart2.svg";
import Heart_img3 from "../../assets/images/Shapes_Image/heart3.svg";
import Emerald_img1 from "../../assets/images/Shapes_Image/Emerald1.svg";
import Emerald_img2 from "../../assets/images/Shapes_Image/Emerald2.svg";
import Emerald_img3 from "../../assets/images/Shapes_Image/Emerald3.svg";
import Emerald_img4 from "../../assets/images/Shapes_Image/Emerald4.svg";
import { PriceContext } from "../../context";
import { isShapeRound } from "../../utils/function";
let lengthValues = 0;
let widthValues = 0;

const RatioForm = ({ name, payload }) => {
  let shapes = {
    Radiant: [
      {
        text: "A",
        img: Radiant_img1,
        value: "1.00",
        upto: "1.09",
      },
      {
        text: "B",
        img: Radiant_img2,
        value: "1.10",
        upto: "1.19",
      },
      {
        text: "C",
        img: Radiant_img3,
        value: "1.20",
        upto: "1.29",
      },
      {
        text: "D",
        img: Radiant_img4,
        value: "1.30",
        upto: "1.30 +",
      },
    ],
    Pear: [
      {
        text: "A",
        img: Pear_img1,
        value: "1.30",
        upto: "1.39",
      },
      {
        text: "B",
        img: Pear_img2,
        value: "1.40",
        upto: "1.49",
      },
      {
        text: "C",
        img: Pear_img3,
        value: "1.50",
        upto: "1.59",
      },
      {
        text: "D",
        img: Pear_img4,
        value: "1.60",
        upto: "1.60 +",
      },
    ],
    Oval: [
      {
        text: "A",
        img: Oval_img1,
        value: "1.20",
        upto: "1.29",
      },
      {
        text: "B",
        img: Oval_img2,
        value: "1.30",
        upto: "1.39",
      },
      {
        text: "C",
        img: Oval_img3,
        value: "1.40",
        upto: "1.49",
      },
      {
        text: "D",
        img: Oval_img4,
        value: "1.45",
        upto: "1.45+",
      },
    ],
    Cushion: [
      {
        text: "A",
        img: Cusion_img1,
        value: "1.00",
        upto: "1.09",
      },
      {
        text: "B",
        img: Cusion_img2,
        value: "1.10",
        upto: "1.19",
      },
      {
        text: "C",
        img: Cusion_img3,
        value: "1.20",
        upto: "1.29",
      },
      {
        text: "D",
        img: Cusion_img4,
        value: "1.30",
        upto: "1.30 +",
      },
    ],
    Round: [
      {
        text: "A",
        img: Round_img1,
        value: "1.00",
        upto: "1.00",
      },
    ],
    Heart: [
      {
        text: "A",
        img: Heart_img3,
        value: "0.95",
        upto: "0.99",
      },
      {
        text: "B",
        img: Heart_img2,
        value: "1.00",
        upto: "1.04",
      },
      {
        text: "C",
        img: Heart_img1,
        value: "1.05",
        upto: "1.05 +",
      },
    ],
    Emerald: [
      {
        text: "A",
        img: Emerald_img1,
        value: "1.00",
        upto: "1.09",
      },
      {
        text: "B",
        img: Emerald_img2,
        value: "1.20",
        upto: "1.29",
      },
      {
        text: "C",
        img: Emerald_img3,
        value: "1.30",
        upto: "1.39",
      },
      {
        text: "D",
        img: Emerald_img4,
        value: "1.40",
        upto: "1.40 +",
      },
    ],
  };
  const getContext = useContext(PriceContext);
  const {
    setPricestepData,
    pricestepData,
    lengthValue,
    setLengthValue,
    widthValue,
    setWidthValue,
    reportType,
  } = getContext;
  const isShapeRoundCheck = isShapeRound(pricestepData.shape);
  const [error, setError] = useState(false);

  const handleRatio = (name, value) => {
    if (reportType === "byGia") {
      if (!isShapeRoundCheck) {
        if (name === "ratio-length") {
          lengthValues = value;
          setLengthValue(value);
        }
        if (name === "ratio-width") {
          widthValues = value;
          setWidthValue(value);
        }
        if (lengthValues > 0 && widthValues > 0) {
          const ratio = Number(lengthValues) / Number(widthValues);
          if (ratio < 0.7 || ratio > 2.0) {
            setPricestepData({ ...pricestepData, ratio: "" });
            setError(true);
            return;
          }
          setError(false);
          let ratioValue = lengthValues / widthValues;
          setPricestepData({ ...pricestepData, ratio: ratioValue.toFixed(2) });
        }
      }
    } else {
      setPricestepData({ ...pricestepData, ratio: value });
    }
  };


  return reportType !== "byGia" ? (
    pricestepData?.shape === "Round" ? (
      <Form.Group className={`form-input-wrapper `}>
        <Form.Control
          type="text"
          pattern="[0-9]*/^\d*\.?\d*$/"
          inputMode="decimal"
          placeholder="N/A"
          value={"N/A"}
          name={name}
          className={pricestepData?.shape === "Round" ? "disable_inp_bg" : ""}
          disabled={pricestepData?.shape === "Round" ? true : false}
        />
      </Form.Group>
    ) : (
      <div className="ratio_outer">
        <div className="row justify-content-center g-2 ratio_container">
          {shapes[payload?.shape]?.map((item, index) => {
            return (
              <div
                className={`col-5 ratio_item ${
                  pricestepData?.ratio === item.value ? "active-ratio-item" : ""
                } ${index % 2 === 0 && "me-2"}`}
                onClick={() => {
                  handleRatio(name, item.value);
                }}
                role="presentation"
              >
                <label
                  className={`shape_box shape_box${index} `}
                  htmlFor={`test3-${index}`}
                ></label>

                <div className="ratio_img_top">
                  <img src={item.img} alt="shape_image" />
                </div>
                <p className="up-to-text">{`${
                  item.upto.includes("+") ? item.upto : "Up to " + item.upto
                }`}</p>
              </div>
            );
          })}
        </div>
      </div>
    )
  ) : (
    <div className="form-wrapper">
      <Form.Group className="form-input-wrapper ratio-length">
        <Form.Control
          type="text"
          pattern="[0-9]*/^\d*\.?\d*$/"
          inputMode="decimal"
          placeholder="10.55"
          name="ratio-length"
          className="disable_inp_bg"
          value={lengthValue}
          disabled={isShapeRoundCheck || reportType === "byGia" ? true : ""}
          onChange={(e) => handleRatio(e.target.name, e.target.value)}
        />
        <Form.Label>length (mm)</Form.Label>
      </Form.Group>
      <Form.Group className="form-input-wrapper ratio-width">
        <Form.Control
          type="text"
          pattern="[0-9]*/^\d*\.?\d*$/"
          inputMode="decimal"
          placeholder="6.50"
          className="disable_inp_bg"
          name="ratio-width"
          value={widthValue}
          disabled={isShapeRoundCheck || reportType === "byGia" ? true : ""}
          onChange={(e) => handleRatio(e.target.name, e.target.value)}
        />
        <Form.Label>width (mm)</Form.Label>
        {error && (
          <span className="error-text">
            Ratio should be in between 0.70 to 2.00
          </span>
        )}
      </Form.Group>
    </div>
  );
};

export default RatioForm;
