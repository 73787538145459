export const diamondColors = [
  { id: 1, img: "yellow", name: "Yellow", value: "Yellow" },
 
  {
    id: 4,
    img: "pink",
    name: "Pink (P.Pink)",
    value: "Pink",
  },
  { id: 11, img: "blue", name: "Blue", value: "Blue" },

];

export const diamondSaturations = [
  "Fancy Deep",
  "Fancy Vivid",
  "Fancy Intense",
  "Fancy",
  "Fancy Light",  
];

export const diamondShapes = [
  "Cushion",
  "Radiant",
  "Oval",
  "Heart",
  "Pear",
  "Emerald",
  "Round",
];

export const diamondClarity = [
  {
    displayName: "FL or IF",
    code: "FL or IF",
    name: "Flawless",
  },
  {
    displayName: "VVS",
    code: "VVS",
    name: "Very, Very Slightly Included",
  },
  {
    displayName: "VS",
    code: "VS",
    name: "Very Slightly Included",
  },
  {
    displayName: "SI1",
    code: "SI1",
    name: "Slightly Included",
  },
  {
    displayName: "SI2",
    code: "SI2",
    name: "Slightly Included",
  },
];

export const diamondFluorescence = [
  "none",
  "Faint Blue",
  "Medium Blue",
  "Strong Blue",
  "Very Strong Blue",
  "Faint Green",
  "Medium Green",
  "Strong Green",
  "Very Strong Green",
  "Faint Yellow",
  "Medium Yellow",
  "Strong Yellow",
  "Very Strong Yellow",
  "Faint Orange",
  "Medium Orange",
  "Strong Orange",
  "Very Strong Orange",
];

export const diamondFluorescenceColor = [
  "None",
  "Blue",
  "Green",
  "Yellow",
  "Orange",
];
export const diamondFluorescenceColorSub = [

  "Faint" ,
  "Medium",
  "Strong",
  "Very Strong",

];


export const diamondDepth = [
  {
    displayName: "Face-Up - Significantly Smaller",
    depthName: "4D+",
    Id:"1"
    
  },
  {
    displayName: "Face-Up - Distinctly Smaller",
    depthName: "4D",
    Id:"2"
  },
  {
    displayName: "Face-Up - Subtly Smaller",
    depthName: "3D",
    Id:"3"
  },
  {
    displayName: "Face-Up - Moderately below",
    depthName: "3D",
    Id:"4"
  },
  {
    displayName: "Face-Up - Slightly Smaller",
    depthName: "2D",
    Id:"5"
  },
  {
    displayName: "Face-Up - Standard",
    depthName: "1D",
    Id:"6"
  },

  {
    displayName: "Face-Up - 1 to 5% larger",
    depthName: "1D",
    Id:"7"
  },
  {
    displayName: "Face-Up - 6 to 15% larger",
    depthName: "1D",
    Id:"8"
  },
  {
    displayName: "Face-Up - 16% or larger",
    depthName: "1D",
    Id:"9"
  },
];


export const diamondDepthForManual = [
  
  {
    displayName: "Face-Up - Small",
    depthName: "3D",
    Id:"4"
  },
 
  {
    displayName: "Face-Up - Standard",
    depthName: "1D",
    Id:"6"
  },
  {
    displayName: "Face-Up - Large",
    depthName: "1D",
    Id:"8"
  },
  
];

export const diamondDepthForRecalculation = [
  
  {
    displayName: "Small",
    depthName: "3D",
    Id:"4"
  },
 
  {
    displayName: "Standard",
    depthName: "1D",
    Id:"6"
  },
  {
    displayName: "Large",
    depthName: "1D",
    Id:"8"
  },
  
];

export const diamondRoundDepth = [
  {
    displayName: "58-63",
    depthName: "2D",
  },
];

export const diamondDesktopPolish = [

  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondPolish = [
  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondDesktopSymmetry = [
 
  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondSymmetry = [
  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondColorInnerGrade = ["1", "2", "3", "4", "4+"];

export const diamondColorDispersion = ["1", "2", "3", "4", "4+"];

export const diamondColorUndertone = [
  {
    value: "1",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "2",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "3",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "4",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "4+",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
];

export const DiaryCauseShape = [
  {
    code: 'CB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CCSB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CIRC',
    shape: 'Round',
  },
  {
    code: 'CMB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CRB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CRMB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CRSC',
    shape: 'Emerald',
  },
  {
    code: 'CRX',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CSMB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CSSC',
    shape: 'Emerald',
  },
  {
    code: 'CSX',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CX',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'EM',
    shape: 'Emerald',
  },
  {
    code: 'HB',
    shape: 'Heart',
  },
  {
    code: 'HMB',
    shape: 'Heart',
  },
  {
    code: 'MHB',
    shape: 'Heart',
  },
  {
    code: 'MHX',
    shape: 'Heart',
  },
  {
    code: 'MMB',
    shape: 'Marquise',
  },
  {
    code: 'MOB',
    shape: 'Oval',
  },
  {
    code: 'MOX',
    shape: 'Oval',
  },
  {
    code: 'MPX',
    shape: 'Pear',
  },

  {
    code: 'MMC',
    shape: 'Marquise',
  },
  {
    code: 'MMX',
    shape: 'Marquise',
  },
  {
    code: 'OB',
    shape: 'Oval',
  },
  {
    code: 'PB',
    shape: 'Pear',
  },
  {
    code: 'PMB',
    shape: 'Pear',
  },
  {
    code: 'RCRB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'RCSB',
    shape: 'Cushion / Radiant',
  },

  {
    code: 'RCSMB',
    shape: 'Cushion / Radiant',
  },

  {
    code: 'SEM',
    shape: 'Emerald',
  },
  {
    code: 'OMB',
    shape: 'Oval',
  },
  {
    code: 'RBC',
    shape: 'Round',
  },
];


export const clarity_T_Value =
 ['SI1',
  'SI2',
  'VS',
  'VVS',
  'IF',
  "FL or IF"
];

export const clarity_T_Value_DESKTOP =
 [
    "FL or IF",
    'VVS',
    'VS',
    'SI1',
    'SI2',  
];

