import React, { useState,useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { diamondColors } from "../../utils/diamondData";
import {PriceContext}  from  "../../context"

const ColorForm = ({ name}) => {
  const getContext = useContext(PriceContext);
  const {
    setPricestepData,pricestepData,reportType
  } = getContext;
  const { color } = pricestepData || {}

  const [show, setShow] = useState(false);
  const [defaultColor, setDefaultColor] = useState({
    img: "yellow",
    value: "Yellow",
  });
  const handleModal = () => {
    if (reportType === "byGia") {
      return;
    }
    setShow(!show);
  };
  const handleSelect = (img, value, name, showName) => {
    setDefaultColor({
      img: img,
      value: showName,
    });
    setPricestepData({ ...pricestepData, [name] :value , colorUndertone  : pricestepData?.colorUndertone==='4+'  ? "" : pricestepData?.colorUndertone  });
   
    setShow(false);
  };

  useEffect(()=>{
    setDefaultColor({
      img: color ? color?.toLowerCase() : "",
      value:  color ? color : "Select",
    });
    setPricestepData({ ...pricestepData, "color": color });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[color])


  return (
    <>
      <div className="form-wrapper">
        <div
          className={`${reportType ==="byGia"? "disable_input":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${pricestepData[name] === "" ? "0.5" : "1"}`,
          }}
        >

          <h6 style={{ 
             padding: "0px" }}>{defaultColor.value}</h6>
        </div>
      </div>
      <ColorSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
      />
    </>
  );
};

export default ColorForm;

const ColorSelector = ({ show, handleModal, handleSelect, name }) => {
  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
          {diamondColors.map((el, i) => (
            <div
              className="pi-option-elem"
              onClick={() => handleSelect(el.img, el.value, name, el.name)}
              key={i}
            >
              <img
                src={`images/diamond-${el.img}-general.svg`}
                alt={el.value}
              />
              {el.name}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
