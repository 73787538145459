import { useState, useContext, useEffect } from "react";
import "./pricing.css";
import { PriceContext } from "../../context";

const PairType = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData  } = getContext;
  const [activeTab, setActiveTab] = useState(false);
  const [activeTabName, setActiveTabName] = useState("single");

  const handleTab = (name) => {
    setActiveTab(!activeTab);
    const text = activeTab ? "single" : "pair";
    setActiveTabName(text);
    setPricestepData({ ...pricestepData, [name]: text });
  };

  useEffect(() => {
    setPricestepData({ ...pricestepData, "singlePair": "single" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-btns-wrapper">
    
      <input
        className="single-pair-check"
        type="checkbox"
        id="checkbox_toggle"
        onChange={() => handleTab(name)}
        checked={activeTab}
      />
      <div 
      className="single-pair-checkbox"
      >
        <label htmlFor="checkbox_toggle" className="single-pair-slide">
          <label
            htmlFor="checkbox_toggle"
            className="single-pair-toggle"
          ></label>
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-text ${
              activeTabName === "single" ? "single" : ""
            }`}
          >
            single
          </label>
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-text ${
              activeTabName === "pair" ? "pair" : ""
            }`}
          >
            pair
          </label>
        </label>
      </div>
    </div>
  );
};

export default PairType;
