import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SplashScreen from "./components/splash";
import Welcome from "./components/Welcome";
import UserLogin from "./components/userLogin";
import StartPricing from "./components/startPricing";
import Disclaimer from "./components/disclaimer";
import PriceDataProvider from "../src/context";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Userprofile from "./components/Userprofile/Userprofile";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import UserRegister from "./components/userRegister/userRegister";
import DesktopResult from "./components/result/desktopResult";
import IndexPricing from "./components/pricing/IndexPricing";
import UnAuthRoute from "./components/AuthRoute/UnAuthRoute";
import Searchhistory from "./components/SearchHistory/Searchhistory";
function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/start-pricing') {
      document.body.classList.add('disclaimer-active');
    } 
    else {
      document.body.classList.remove('disclaimer-active');
    }

    // Clean up on unmount
    return () => {
      document.body.classList.remove('disclaimer-active');
    };
  }, [location]);

  return (
    <>
      <PriceDataProvider>
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/welcome" element={<UnAuthRoute component={<Welcome />} />} />
          <Route path="/user-login" element={<UnAuthRoute component={<UserLogin />} />} />
          <Route
            path="/start-pricing"
            element={<StartPricing />}
          />
          <Route
            path="/disclaimer"
            element={<AuthRoute component={<Disclaimer />} />}
          />
           <Route
            path="/pricing"
            element={<AuthRoute component={<IndexPricing />} />}
          />
          <Route
            path="/result"
            element={<AuthRoute component={<DesktopResult />} />}
          />
          <Route path="/profile" element={<Userprofile />} />
          <Route path="/price-intelligence-search-history" element={<Searchhistory />} />
          <Route path="/user-register" element={<UserRegister />} />
        </Routes>
      </PriceDataProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        style={{ width: "400px" }}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
